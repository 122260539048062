* {
  box-sizing: border-box;
  scroll-behavior: smooth;
}

body, html {
    margin: 0;
    padding: 0;
    background-color: #f6f8fb;
    max-height: 100vh;
    width: 100vw;
}